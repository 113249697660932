<template>
  <el-popover
    placement="bottom"
    trigger="click">
    <md-list>
      <!-- <md-list-item v-if="profile.roles && (profile.roles.includes('super_admin') || profile.roles.includes('admin') || profile.roles.includes('group_chief') || profile.roles.includes('department_chief'))">
        <UserPricing />
      </md-list-item> -->
      <md-list-item>
        <span
          class="md-list-item-text"
          style="
              color: #ccc;
              font-size: 12px;
              align-items: center;
          ">Version 2.9.11
        </span>
      </md-list-item>
      <md-list-item>
        <LocaleChanger />
      </md-list-item>
      <!-- <md-list-item>
        <Notifications />
      </md-list-item> -->
      <md-list-item @click="editProfile">
        <md-icon>person</md-icon>
        <span class="md-list-item-text">{{profile.username}}</span>
      </md-list-item>
      <md-divider class="md-inset"></md-divider>
      <md-list-item @click="handleLogout">
        <md-icon>exit_to_app</md-icon>
        <span class="md-list-item-text">Đăng xuất</span>
      </md-list-item>
    </md-list>
    <md-toolbar slot="reference" class="md-transparent" md-elevation="0" style="min-height: 0px">
      <div class="home-avatar">
        <img :src="profile.avatar | media" alt="AVATAR">
      </div>
      <div class="sub_main">{{ $t('MENU_PROFILE') }}</div>
    </md-toolbar>
  </el-popover>
</template>

<script>
import { checkIsDevice, isApp } from '@/utils/check-device'
import { mapGetters } from 'vuex'
import get from 'lodash/get'
import store from '@/store'
// import UserPricing from '@/views/UserPricing'
// import Notifications from '@/views/home/Notifications'
import LocaleChanger from '@/components/I18n/LocaleChanger.vue'

export default {
  components: {
    // UserPricing,
    LocaleChanger
    // Notifications
  },
  computed: {
    ...mapGetters([
      'profile'
    ])
  },
  methods: {
    checkIsIos() {
      return !isApp() || !checkIsDevice('iOS')
    },
    editProfile() {
      this.$router.push({ name: 'Profile' })
    },
    async handleLogout() {
      try {
        await store.dispatch('user/logout', {
        })
        location.reload()
      } catch (error) {
        this.error = get(error, 'response.data.error.msg') || error.message
        setTimeout(() => {
          this.error = ''
        }, 3000)
      }
    }
  }
}
</script>

<style lang="scss">
.home-avatar {
      display: relative;
      cursor: pointer;
      max-width: 30px;
      img {
        border-radius: 50%;
      }
    }
    .sub_main {
      cursor: pointer;
      margin-left: 5px;
      font-weight: 600;
      color: #919191;
    }
</style>
